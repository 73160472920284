import { panelRoutes as mokamRoutes } from '@shopuptech/ui-config';
import { REDX_PANEL_PERMISSION } from '../../override';
import env from '../../variables';

const redxPanelRoutes = [
  {
    name: 'Redx Admin',
    path: REDX_PANEL_PERMISSION,
    component: () => null,
  },
];

export const routes = env.isRedxAdmin ? redxPanelRoutes : mokamRoutes;
