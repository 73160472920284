import { moduleName } from '../moduleInfo';
import type { RootState } from '../reducers';

type ActionType = {
  type: string;
  [key: string]: any;
};

export const action = (actionArgs: ActionType) => ({
  ...actionArgs,
  meta: { moduleName },
});

export const getRootState = (state: any): RootState => state[moduleName];

export const isValidPhone = (value: number | string): boolean => {
  if (typeof value === 'string' || typeof value === 'number') {
    const result =
      value.toString().match(/^(?:880)?((01|1)[1,3-9]\d{8})$/) !== null;
    return result && !value.toString().includes('.');
  }
  return false;
};

export type IsEmptyValueType =
  | string
  | number
  | undefined
  | null
  | boolean
  | any;

export const isEmpty = (value: IsEmptyValueType): boolean =>
  value === undefined || value === null || value === '';
