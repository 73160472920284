import React from "react"
import classNames from "classnames/bind";
import { UserInfoDropdownProps } from "./navbar.types"
import styles from './userinfo.module.css';
import { Icon } from "@shopuptech/expo-shopup-ui";

const cx = classNames.bind(styles)
const UserInfo = ({ user, handleLogout, collapsable }: UserInfoDropdownProps) => {
  const { name, email, phone } = user
  return (
    <div className={cx('userinfo', { collapsable })}>
      <div className={cx('user')}>
        <Icon name="User" size="small" color="PRIMARY_BTN_COLOR" />
        {
          !collapsable &&
          <div>
            <p className={cx('font')}>{name}</p>
            {
              email &&
              <p className={cx('font', 'fontsmall')}>{email}</p>
            }
            {
              phone &&
              <p className={cx('font', 'fontsmall')}>{phone}</p>
            }
            <p className={cx('font', 'fontsmall')}>ID: {user.id}</p>
          </div>
        }
      </div>
      <div onClick={handleLogout} className={cx('logout')}>
        <Icon name="Logout" size="small" color="TRANSPARENCY_BLACK_60" />
        {
          !collapsable &&
          <p className={cx('font')}>Logout</p>
        }
      </div>
    </div>
  )
}

export default UserInfo