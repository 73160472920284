import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoginScreen } from '@shopuptech/auth-login';
import env from '../../../variables';
import { theme } from '../../../config';
import { types } from '@shopuptech/ui-core';
import { checkPermissionsAction } from '../../../mainActions';
import useRequireLogin from '../../../hooks/requireLoginHook';

const urlTemplate = `${window.location.origin}/forgot-password/{token}`;

const gLoginApiRoute = `${env.authPrefix}/login/google`;

const LoginMain = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch: types.Dispatch<any> = useDispatch();
  useRequireLogin(isLoggedIn);
  const handleSuccessCallback = () => {
    dispatch(checkPermissionsAction())
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch((error) => {
        console.log('checkPermissionsAction', error);
      });
  };

  return (
    <LoginScreen
      googleClientID={env.googleClientID}
      loginSuccessCallback={handleSuccessCallback}
      theme={theme}
      urlTemplate={urlTemplate}
      gLoginApiRoute={gLoginApiRoute}
      forgotApiRoute={env.forgotPwdApiRoute}
      emailApiRoute={env.emailApiRoute}
      sendOtpApiRoute={env.sendOtpApiRoute}
      submitOtpApiRoute={env.submitOtpApiRoute}
      enablePhoneLogin={env.enablePhone}
      enablePhoneRegister={env.enablePhoneRegister}
      enableEmailLogin={env.enableEmail}
      enableGoogleLogin={env.enableGlogin}
      initialTab={env.initialTab as any}
      customHeaderVariables={{
          timebaseHashHeader: env.timebaseHashHeader,
          hashSecret: env.hashSecret,
          duration: env.hashDuration,
        }}
    />
  );
};

export default LoginMain;
