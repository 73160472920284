import React from "react";
import classNames from "classnames/bind";
import { LogoProps } from "./navbar.types";
import styles from "./logo.module.css"

const cx = classNames.bind(styles)
const Logo = ({ logo, logoClick, collapsable, logoSmall }: LogoProps) => {
  const src = collapsable && logoSmall ? logoSmall : logo;
  return (
    <div
      onClick={logoClick}
      role={'button'}
      onKeyPress={logoClick}
      className={cx('logo', {
        logoCollapsed: collapsable
      })}
    >
      <img
        src={src}
        alt={'admin panel logo'}
        className={cx('image')}
      />
    </div>
  )
}

export default Logo;