import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import Logo from './Logo';
import { SidebarProps } from './navbar.types';
import styles from './sidebar.module.css';
import SidebarItem from './SidebarItem';
import UserInfo from './UserInfo';

const cx = classNames.bind(styles);

const Sidebar = ({
  logo,
  logoClick,
  routes,
  navigate,
  collapsable,
  showTopBar,
  user,
  handleLogout,
  logoSmall,
}: SidebarProps) => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if(isMobileView && !user.isLoggedin){
    return (
      <div></div>
    );
  }

  return (
    <div
      className={cx('sidebar')}
    >
      <Logo
        logo={logo}
        logoClick={logoClick}
        collapsable={collapsable}
        logoSmall={logoSmall}
      />
      <div
        className={cx('sidebarLinkContainer', {
          sidebarLinkContainerSmall: !showTopBar,
        })}
      >
        {routes.map((route) => {
          return (
            <SidebarItem
              key={route.path}
              route={route}
              navigate={navigate}
              collapsable={collapsable}
            />
          );
        })}
      </div>
      {!showTopBar && user && user.id !== 0 && (
        <UserInfo
          user={user}
          handleLogout={handleLogout}
          collapsable={collapsable}
        />
      )}
    </div>
  );
};

export default Sidebar;
