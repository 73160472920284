export const REDX_PANEL_PERMISSION = '/redx';
export const navigateOveride = (path: string, href: boolean) => {
  console.log('path');
  if (typeof path === 'string' && path.includes(REDX_PANEL_PERMISSION)) {
    console.log('called');
    window.location.href = '/';
    return true;
  }
  return false;
};
