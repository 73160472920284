import React from 'react';
import classNames from 'classnames/bind';
import { useRouteMatch } from 'react-router-dom';
import styles from './sidebarlink.module.css';
import { SidebarLinkProps } from './navbar.types';

const cx = classNames.bind(styles);
const SidebarLink = ({
  path,
  name,
  exact,
  navigate,
  href,
  padding,
  paddingLeft,
}: SidebarLinkProps) => {
  const match = useRouteMatch({
    path,
    exact,
  });

  const isHref = !!href;
  return (
    <div onClick={() => navigate(path, isHref)}>
        <div
          className={cx('routename', {
            active: match,
            padding: padding,
            paddingLeft: paddingLeft,
          })}
        >
          {match && <div className={cx('activeIndicator')} data-testid="active-indicator" />}
          {name}
        </div>
    </div>
  );
};

export default SidebarLink;
