import React from 'react';

const defaultProps = {
  width: 88,
  height: 88,
  fill: '#FFF',
};

function SvgAccessDenied({ width, height, fill }: typeof defaultProps) {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <circle cx={44} cy={44} r={44} fill="#E76366" />
        <g fillRule="nonzero">
          <path
            fill={fill}
            d="M31.716 31.324a3 3 0 013.25 2.727l-.349-3.985a3 3 0 015.978-.523l-.35-3.985a3 3 0 015.978-.523l.349 3.985a3 3 0 015.977-.523l1.83 20.92 6.615-4.064a5.005 5.005 0 012.182-.72 2.82 2.82 0 013.055 2.563v.012a2.815 2.815 0 01-.648 2.064l-11.756 14.01a14.48 14.48 0 01-9.833 5.113c-6.602.577-12.423-4.307-13-10.909l-2.005-22.912a3 3 0 012.727-3.25z"
          />
          <path
            d="M47.793 42.968a1 1 0 01-1.083-.91l-1.676-19.146a2.934 2.934 0 011.19 2.124l1.569 17.932zm-5.977.523a1 1 0 01-1.084-.91L39.406 27.42a2.934 2.934 0 011.19 2.124l1.22 13.947zm-6.849-9.44l.872 9.963a1 1 0 01-1.084-.91l-.978-11.178a2.934 2.934 0 011.19 2.125z"
            fill="#FFB74D"
          />
          <path
            fill="#000"
            d="M44.08 69.39a15.372 15.372 0 0010.512-5.47L66.35 49.91a3.83 3.83 0 00-3.26-6.274 6.012 6.012 0 00-2.616.863l-5.238 3.22-1.689-19.31a4 4 0 00-6.279-2.925l-.047-.536a4 4 0 10-7.97.697l.048.536a4 4 0 00-5.677 3.972l.047.536a4 4 0 00-5.675 3.972l2.004 22.912c.636 7.148 6.934 12.434 14.084 11.818zM63.264 45.63a1.828 1.828 0 011.556 2.995L53.06 62.634a13.386 13.386 0 01-9.154 4.764c-6.048.522-11.38-3.95-11.916-9.999l-2.005-22.912a2 2 0 013.985-.35l.784 8.967a1 1 0 001.993-.175l-1.133-12.95a2 2 0 013.984-.349l1.133 12.95a1 1 0 001.993-.174l-1.482-16.935a2 2 0 013.985-.348l1.482 16.935a1 1 0 001.992-.174l-1.133-12.951a2 2 0 013.985-.349l1.83 20.92a1 1 0 001.52.765l6.623-4.065a4.017 4.017 0 011.737-.575z"
          />
        </g>
      </g>
    </svg>
  );
}

SvgAccessDenied.defaultProps = defaultProps;

export default SvgAccessDenied;
