import { helpers } from '@shopuptech/ui-core';
import env from '../../../variables'

const { formatUrl } = helpers;

const authFormattedUrl = (url: string) =>
  formatUrl(url, { prefix: '/auth', postfix: '' });

export const exampleRoute = () => '/new_index.json';
export const sendOtp = () => authFormattedUrl('/v0/login/phone/init');
export const submitOtp = () => authFormattedUrl('/v0/login/phone');
export const gloginApi = () => authFormattedUrl('/v0/login/google');
export const emailLoginApi = () => authFormattedUrl('/v0/login/email');
export const forgotPwdApi = () =>
  authFormattedUrl('/v0/login/email/request-password-reset');

export const resetPwdApi = () => env.resetPwdApiRoute;
