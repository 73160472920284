import React from 'react';
import { SidebarItemProps } from './navbar.types';
import SidebarLink from './SidebarLink';

const SidebarItem = ({ route, navigate, collapsable }: SidebarItemProps) => {
  const { path, targetPath } = route;

    return (
      <SidebarLink
        padding
        path={targetPath ?? path}
        name={route.name}
        navigate={navigate}
        href={route.href}
        collapsable={collapsable}
        icon={route.icon}
        paddingLeft
      />
    );
};

export default SidebarItem;
