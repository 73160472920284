import React from 'react';
import classNames from 'classnames/bind';
import Sidebar from './Sidebar';
import styles from './navbar.module.css';
import { NavbarProps } from './navbar.types';

const cx = classNames.bind(styles);

const Navbar = ({
  logoClick,
  user,
  children,
  logo,
  collapsable,
  routes,
  showTopBar,
  navigate,
  handleLogout,
  logoSmall,
  showCollapseIcon,
}: NavbarProps) => {
  return (
    <div data-testid="navbar" className={cx('navbar')}>
      <Sidebar
        logo={logo}
        logoClick={logoClick}
        user={user}
        collapsable={collapsable}
        routes={routes}
        navigate={navigate}
        showTopBar={showTopBar}
        handleLogout={handleLogout}
        logoSmall={logoSmall}
        showCollapseIcon={showCollapseIcon}
      />
      <div className={cx('children', { childHasTopbar: showTopBar })}>
        {children}
      </div>
    </div>
  );
};

export default Navbar;
