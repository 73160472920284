import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { checkLoginAction, checkPermissionsAction } from '../mainActions';
import { LOGIN_PATH } from '../Navigation';
import { helpers, types } from '@shopuptech/ui-core';
import type { ItemsEntity } from '../mainAction.types';
import { Toast } from '@shopuptech/expo-shopup-ui';
const { getPermissionDict, initialisePermissionsHook } = helpers;

const useRequireLogin = (isLoggedIn: boolean) => {
  const dispatch: types.Dispatch<any> = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(isLoggedIn);
  const [permissions, setPermission] = useState<types.permissionType>({});
  const [permissionList, setPermissionList] = useState<ItemsEntity[]>([]);
  const ref = location.search ? location.search : '';
  useEffect(() => {
    const promises = [];
    promises.push(dispatch(checkLoginAction()));
    promises.push(dispatch(checkPermissionsAction()));
    Promise.all(promises)
      .then((responseArray) => {
        const [loginResponse, permissionResponse] = responseArray;
        setLoggedIn(true);
        console.log('loginResponse: ', loginResponse);
        const { data: { items = [] } = {} } = permissionResponse || {};
        if (Array.isArray(items) && items.length > 0) {
          if (location.pathname !== '/dashboard') {
            history.replace('/dashboard');
          }
          setPermissionList(items);
          initialisePermissionsHook(items);
          const permissions = getPermissionDict(items);
          setPermission(permissions);
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        if (typeof error === 'string' && loggedIn) {
          Toast(error);
        }
        if (
          location.pathname !== LOGIN_PATH &&
          !location.pathname.includes('/forgot-password')
        ) {
          history.replace(LOGIN_PATH + ref);
        }
      });
  }, [isLoggedIn]);

  return {
    loggedIn,
    permissions,
    permissionList,
  };
};

export default useRequireLogin;
