import React from 'react';
import { Flex, Text, StyleSheet, getColors } from '@shopuptech/expo-shopup-ui';
import SvgAccessDenied from '../../Icons/SvgAccessDenied';
import styles from './nopermissionscreen.module.css';
import { theme } from '../../config';

const { BLACK, TITLE_TEXT_COLOR, WHITE } = getColors(theme);
const height = window.innerHeight;

const uikitStyle = StyleSheet.create({
  accessText: {
    color: TITLE_TEXT_COLOR,
  },
  overAll: {
    backgroundColor: WHITE,
    width: 474,
    height: 312,
    borderRadius: 4,
    elevation: 5,
    shadowColor: BLACK,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.06,
    shadowRadius: 10,
  },
  contactText: {
    paddingHorizontal: 53,
    paddingTop: 26,
  },
  overAllConatiner: {
    height: height,
  },
  mail: {
    color: TITLE_TEXT_COLOR,
  },
});
const NoPermissionScreen = () => {
  return (
    <Flex center middle overrideStyle={uikitStyle.overAllConatiner}>
      <Flex center overrideStyle={uikitStyle.overAll}>
        <div className={styles.svg}>
          <SvgAccessDenied />
        </div>
        <Text bold size={20} overrideStyle={uikitStyle.accessText}>
          Access Denied!
        </Text>
        <Text testID='no-permission-message' size={16} color="gray" overrideStyle={uikitStyle.contactText}>
          Sorry, you don’t have access to this page.
        </Text>
        <div>
          <Text testID='no-permission-contact-prefix' size={16} color="gray">
            Please contact
          </Text>
          <Text testID='no-permission-email' size={16} overrideStyle={uikitStyle.mail}>
            {' '}
            access.it@shopf.co{' '}
          </Text>
          <Text testID='no-permission-contact-suffix' size={16} color="gray">
            for more information.
          </Text>
        </div>
      </Flex>
    </Flex>
  );
};

export default NoPermissionScreen;
