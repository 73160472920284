import React from 'react';
import MainRoutes from './MainRoutes';
import { Provider } from 'react-redux';
import store from './store';
import { ThemeProvider } from '@shopuptech/expo-shopup-ui';
import { theme } from './config';

const Main = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme} choosenLang='en'>
        <MainRoutes />
      </ThemeProvider>
    </Provider>
  );
};

export default Main;
