import {
  OTP_INIT_ACTION,
  OTP_ACTION_SUCCESS,
  OTP_ACTION_ERROR,
  SUBMIT_OTP_INIT_ACTION,
  SUBMIT_OTP_ACTION_SUCCESS,
  SUBMIT_OTP_ACTION_ERROR,
  GOOGLE_LOGIN_INIT,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_ERROR,
  EMAIL_LOGIN_INIT,
  EMAIL_LOGIN_SUCCESS,
  EMAIL_LOGIN_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../../actions';
import type {
  EmailLoginAllActions,
  ForgotPwdAllActions,
  GloginState,
  GoogleLoginAllActions,
  OTPAllActions,
  ResetPwdAllActions,
  SubmitOTPAllActions,
  SubmitOtpState,
} from './login.types';

const sendOtp = (
  state = {
    isLoading: false,
  },
  action: OTPAllActions,
) => {
  switch (action.type) {
    case OTP_INIT_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case OTP_ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OTP_ACTION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const submitOtp = (
  state: SubmitOtpState = {
    isLoading: false,
    error: '',
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: false,
    phone: '',
    phoneVerified: false,
    created: '',
    updated: '',
    roles: [],
    scopes: [],
    name: '',
  },
  action: SubmitOTPAllActions,
) => {
  switch (action.type) {
    case SUBMIT_OTP_INIT_ACTION:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case SUBMIT_OTP_ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case SUBMIT_OTP_ACTION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const glogin = (
  state: GloginState = {
    isLoading: false,
    error: '',
    success: '',
  },
  action: GoogleLoginAllActions | EmailLoginAllActions,
) => {
  switch (action.type) {
    case EMAIL_LOGIN_INIT:
    case GOOGLE_LOGIN_INIT:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case EMAIL_LOGIN_SUCCESS:
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case EMAIL_LOGIN_ERROR:
    case GOOGLE_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const forgotPassword = (
  state: GloginState = {
    isLoading: false,
    error: '',
    success: '',
  },
  action: ForgotPwdAllActions,
) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const resetPassword = (
  state: GloginState = {
    isLoading: false,
    error: '',
    success: '',
  },
  action: ResetPwdAllActions,
) => {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loginReducers = {
  sendOtp,
  submitOtp,
  glogin,
  forgotPassword,
  resetPassword,
};

export default loginReducers;
