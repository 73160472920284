import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './Navigation';

import { routes as routesmainhome } from './modules/mainhome/routes';

const combinedRoutes = [...routesmainhome];

const initialRoute = '/';

const MainRoutes = () => {
  console.log('combinedRoutes ', combinedRoutes);
  return (
    <Router>
      <Navigation combinedRoutes={combinedRoutes} initialRoute={initialRoute} />
    </Router>
  );
};

export default MainRoutes;
