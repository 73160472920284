export const EXAMPLE_ACTION: 'example/EXAMPLE_ACTION2' =
  'example/EXAMPLE_ACTION2';
export const EXAMPLE_ACTION_SUCCESS: 'example/EXAMPLE_ACTION_SUCCESS' =
  'example/EXAMPLE_ACTION_SUCCESS';
export const EXAMPLE_ACTION_ERROR: 'example/EXAMPLE_ACTION_ERROR' =
  'example/EXAMPLE_ACTION_ERROR';

export const OTP_INIT_ACTION: 'login/OTP_INIT_ACTION' = 'login/OTP_INIT_ACTION';
export const OTP_ACTION_SUCCESS: 'login/OTP_ACTION_SUCCESS' =
  'login/OTP_ACTION_SUCCESS';
export const OTP_ACTION_ERROR: 'login/OTP_ACTION_ERROR' =
  'login/OTP_ACTION_ERROR';

export const SUBMIT_OTP_INIT_ACTION: 'login/SUBMIT_OTP_INIT_ACTION' =
  'login/SUBMIT_OTP_INIT_ACTION';
export const SUBMIT_OTP_ACTION_SUCCESS: 'login/SUBMIT_OTP_ACTION_SUCCESS' =
  'login/SUBMIT_OTP_ACTION_SUCCESS';
export const SUBMIT_OTP_ACTION_ERROR: 'login/SUBMIT_OTP_ACTION_ERROR' =
  'login/SUBMIT_OTP_ACTION_ERROR';

export const GOOGLE_LOGIN_INIT: 'login/GOOGLE_LOGIN_INIT' =
  'login/GOOGLE_LOGIN_INIT';
export const GOOGLE_LOGIN_SUCCESS: 'login/GOOGLE_LOGIN_SUCCESS' =
  'login/GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_ERROR: 'login/GOOGLE_LOGIN_ERROR' =
  'login/GOOGLE_LOGIN_ERROR';

export const EMAIL_LOGIN_INIT: 'login/EMAIL_LOGIN_INIT' =
  'login/EMAIL_LOGIN_INIT';
export const EMAIL_LOGIN_SUCCESS: 'login/EMAIL_LOGIN_SUCCESS' =
  'login/EMAIL_LOGIN_SUCCESS';
export const EMAIL_LOGIN_ERROR: 'login/EMAIL_LOGIN_ERROR' =
  'login/EMAIL_LOGIN_ERROR';

export const FORGOT_PASSWORD = 'login/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'login/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'login/FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD = 'login/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'login/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'login/RESET_PASSWORD_ERROR';
