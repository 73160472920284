import {
  OTP_INIT_ACTION,
  SUBMIT_OTP_INIT_ACTION,
  GOOGLE_LOGIN_INIT,
  EMAIL_LOGIN_INIT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../../actions';
import { action } from '../../Utils/helpers';
import type {
  EmailLoginInitPayload,
  ForgotPwdInitPayload,
  GoogleLoginInitPayload,
  ResetPwdInitPayload,
  submitOtpActionPayloadType,
} from './login.types';

export const loginInitAction = (payload: string) =>
  action({
    type: OTP_INIT_ACTION,
    payload,
  });

export const submitOtpInitAction = (payload: submitOtpActionPayloadType) =>
  action({
    type: SUBMIT_OTP_INIT_ACTION,
    payload,
  });

export const googleLoginInitAction = (payload: GoogleLoginInitPayload) =>
  action({
    type: GOOGLE_LOGIN_INIT,
    payload,
  });

export const emailLoginInitAction = (payload: EmailLoginInitPayload) =>
  action({
    type: EMAIL_LOGIN_INIT,
    payload,
  });

export const forgotPwdInitAction = (payload: ForgotPwdInitPayload) =>
  action({
    type: FORGOT_PASSWORD,
    payload,
  });

export const resetPwdInitAction = (payload: ResetPwdInitPayload) =>
  action({
    type: RESET_PASSWORD,
    payload,
  });
